* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

[ng-cloak] {
  display: none;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
    color: #111;
    -webkit-font-smoothing: antialiased;
    line-height: 1.75;
    &:after {
      background: url('../images/background/moon.svg');
      background-repeat: no-repeat;
      background-size: 12vmin;
      content: '';
      height: 12vmin;
      width: 12vmin;
      position: fixed;
      transform: translate3d(0,0,0);
      top: calc(3vw + #{$nav-height});
      left: 3vw;

    }
}

.container {
  width: 100%;
  margin: 0 auto;

  @include xs {
    width: 756px;
  }

  @include md {
    width: 1010px;
  }

  @include lg {
    width: 1420px;
  }

  @include xl {
    width: 1660px;
  }
}

.light {
  font-weight: 200;
}


section {
  font-family: 'Futura W01';
  padding: 2em 0;
  overflow: hidden;
  z-index: 1;
  position: relative;

  h1 {
    font-size: 48px;
    text-align: center;
    padding-bottom: 0.4em;
    margin-bottom: 1em;
    position: relative;
    font-weight: 900;

    &:after {
      content: '';
      display: block;
      width: 2em;
      height: 2px;
      background: $line-light;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
}
