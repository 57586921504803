.registry {
  background: $green;
  border-top: 1px solid darken($green, 20%);

  h1:after {
    background: $line-dark;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
  }

  .registry-info, .registry-links {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    width: 100%;
    padding: 0 2em;

    @include sm {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
      width: 65%;
    }

    @include md {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
      width: 50%;
    }
  }

  .registry-links {
    list-style: none;
    padding: 0 2em;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;

    @include sm {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
      width: 35%;
    }

    @include md {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
      width: 50%;
    }

    li {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
      width: 50%;
      display: flex;
      padding: 0.5em;

      &:nth-child(odd) {
        padding-left: 0;
      }

      &:nth-child(even) {
        padding-right: 0;
      }

      @include sm {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        width: 100%;
        padding: 1em;

        &:nth-child(odd) {
          padding-left: 1em;
        }

        &:nth-child(even) {
          padding-right: 1em;
        }
      }

      @include md {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        width: 50%;
      }

      a {
        align-self: center;
        background: $grey;
        border: 1px solid rgba(0,0,0,.4);
        border-radius: 3px;
        display: block;
        width: 100%;
        font-size: 0;
        height: 130px;
        transition: 0.3s border, 0.3s background;
        position: relative;

        &:hover {
          border: 1px solid rgba(0,0,0,1);
        }

        &:active {
          background: darken($grey, 5%);
        }
      }

      @mixin name-padding($padding) {
        top: $padding / 2;
        bottom: $padding / 2;
        left: $padding;
        right: $padding;
      }

      .name {
        position: absolute;
        background: 50% 50% no-repeat;
        background-size: contain;
        opacity: 0.6;
        transition: 0.3s opacity;

        @include name-padding(24px);

        @include xs {
          @include name-padding(40px);
        }

        @include sm {
          @include name-padding(15px);
        }

        @include md {
          @include name-padding(24px);
        }

        @include lg {
          @include name-padding(34px);
        }
      }

      a:hover .name {
        opacity: 1;
      }

      &:nth-child(1) {
        .name {
          background-image: url('../images/leukemia_lymphoma_society.svg');
        }
      }

      &:nth-child(2) {
        .name {
          background-image: url('../images/aspca.svg');
        }
      }

      &:nth-child(3) {
        .name {
          background-image: url('../images/amazon.svg');
        }
      }

      &:nth-child(4) {
        .name {
          background-image: url('../images/Bed_Bath__and__Beyond.svg');
        }
      }
    }
  }
}
