.places-to-stay {
  background: $grey;
  background: url('../images/background/bg-hotels.png');
  text-align: center;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 5em;

  h1:after {
    background: $line-dark;
  }

  p {
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  h3 {
    margin-bottom: 0.5em;
  }

  a {
    color: darken($green, 20%);

    &:hover {
      color: darken($green, 40%);
    }

    &:active {
      color: #000;
    }
  }

  .hotels {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: 4em auto;

    @include xs {
      flex-direction: row;
    }

    a {
      background: darken($green, 20%);
      transition: 0.3s background;
      color: #fff;
      display: inline-block;
      width: 6em;
      text-decoration: none;
      font-size: 18px;
      text-transform: uppercase;
      padding: 0.3em 0;

      &:hover {
        background: darken($green, 40%);
        color: #fff;
      }

      &:active {
        color: #000;
      }

      &:first-of-type {
        margin-right: 0.5em;
      }
    }

    li {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;

      @include xs {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        margin-bottom: 0;
      }

      &:first-child {
        margin-bottom: 2em;

        @include xs {
          margin-bottom: 0;
        }
      }
    }

    img {
      width: 70%;
      border-radius: 50%;
      border: 4px solid #fff;
      box-shadow: 0 0 0 1px rgba(#000, 0.6);
    }
  }
}
