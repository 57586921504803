@function rand($min: 0, $max: 1) {
  $tempRandom: random();
  @if $tempRandom < $min {
    $tempRandom: $min;
  }

  @if $tempRandom > $max {
    $tempRandom: $max;
  }

  @return $tempRandom;
}

.sky {
  background: linear-gradient(#00171A, #325A57 50%);

  background-position: center center;
  background-repeat: repeat;
  background-size: auto auto;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate3d(0,0,0);
  width: 100vw;
  z-index: 0;

  $number-of-layers: 9;
  .layer{
    position:absolute;
    top:0;
    left:0;
  }

}

.site-header {
  width: 100vw;
  height: 100vh;
  position: relative;
  // background-attachment: fixed;
  background-size: 500px auto;
  overflow: hidden;
  perspective: 0px;
  z-index: 1;

  .title-container {
    display: flex;
    height: calc(100vh - 30vw);
    margin-top: $nav-height;
  }

  h1 {
    font-family:'Rockwell W01';
    font-weight: 800;
    text-transform: uppercase;
    align-self: center;
    text-align: right;
    width: 80vmin;
    margin: 0 auto;
    font-size: 14vmin;
    padding-bottom: 0.25em;
    color: #8ABEAF;
    line-height: 0.9;
    background: url('../images/and.svg') no-repeat 6% bottom;
    background-size: 40% auto;
    position: relative;
    text-shadow: 0 5px 30px rgba(0,34,38,.66);
    z-index: 1;
  }

  .background, .background > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: 100% auto;
    z-index: 2;
    transform-style: preserve-3d;
    transform: translateZ(0);
    .b7 {
      z-index: 3;
      // transform: translate3d(0, 7vmax, 0)
      // background-position: 50% 145%;
    }
  }

  $i: 7;
  @while $i > 0 {
    .b#{$i} {
      background-image: url('../images/background/#{$i}.svg');
      z-index: $i;
    }
    $i: $i - 1;
  }

  .b7 {
    background-image: url('../images/background/7-old.svg');
  }
}
