
.rsvp {
  $desktop-width: 800px;

  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  padding: 0;

  form {
    background: $pink;
    width: calc(#{$desktop-width / 2} + 4em);
    max-width: 100%;
    max-height: calc(100% - 1em);
    overflow: visible;
    margin: auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    transition: 1s all;
    font-size: 20px;
    padding: 2em;
    border-bottom: 1px solid #000;
    border-radius: 0 0 10px 10px;

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  .main-info, .additional-info {
    vertical-align: top;
    white-space: normal;
    display: block;

    @include sm {
      display: inline-block;
      width: #{$desktop-width / 2};
    }


    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  .main-info {
    font-size: 1.3em;

    @include sm {
      margin-right: -0.32em;
    }
  }

  h1 {
    margin-top: 0;
  }

  label {
    font-size: 1em;
    display: block;
    margin-top: 1em;

    &:first-child {
      margin-top: 0;
    }
  }

  input[type="text"], textarea {
    width: 100%;
    appearance: none;
    border: none;
    font-size: 1em;
    padding: 0.3em;
    margin-top: 0.4em;
    display: block;

    background: rgba(#fff, 0.4);
    // border: 3px solid rgba(#000, 0);
    box-shadow: 0 0 0 0 rgba(#000, 0.4);
    transition: 0.2s box-shadow;

    &:focus {
      // border: 3px solid rgba(#000, 0.4);
      box-shadow: 0 0 0 3px rgba(#000, 0.4);
      outline: none;
    }
  }

  textarea {
    height: 4em;
  }



  .rsvp-yes-no {
    margin: 2em 0;

    label {
      display: block;
      margin: 0.5em 0;

      &:before {
        content: '\2713';
        font-size: 2em;
        color: rgba(#000, 0);
        display: inline-block;
        vertical-align: middle;
        width: 0.6em;
        height: 0.6em;
        line-height: 0.5;
        background: rgba(#fff, 0.4);
        box-shadow: 0 0 0 0 rgba(#000, 0.4);
        transition: 0.2s box-shadow, 0.2s color;
        position: relative;
        top: -0.07em;
        margin-right: 0.5em;
      }
    }


    input[type="radio"] {
      opacity: 0;
      // font-size: 0;
      position: absolute;

      &:checked + label {
        &:before {
          color: rgba(#000, 1);
        }
      }

      &:focus + label {
        &:before {
          box-shadow: 0 0 0 3px rgba(#000, 0.4);
        }
      }
    }
  }

  button {
    appearance: none;
    border: none;
    font-size: 1em;
    background: rgba(#fff, 0.5);
    padding: 0.45em 0.75em;
    float: right;
    cursor: pointer;
    transition: 0.2s background;

    &.desktop-submit {
      display: none;
    }

    &.mobile-submit {
      margin-top: 1em;
    }

    @include sm {
      &.desktop-submit {
        display: block;
      }

      &.mobile-submit {
        display: none;
      }
    }

    &:hover {
      background: rgba(#fff, 0.8);
    }
  }


  .additional-info {
    opacity: 1;
    position: relative;

    @include sm {
      margin-left: 3em;
    }

    &.ng-hide-remove, &.ng-hide-add {
      transition: opacity 0.3s;
    }

    &.ng-hide {
      opacity: 0;
    }


    @include sm {
      &:before {
        content: '';
        position: absolute;
        top: 1em;
        bottom: 1em;
        left: calc(-1.5em - 1px);
        width: 2px;
        background: rgba(#000, 0.3);
      }
    }
  }

  label[for="name"] {
    display: block;
  }

  form.rsvp-yes {
    @include sm {
      width: calc(#{$desktop-width} + 7em);
    }
  }

  &.ng-hide-remove {
    transition: all 0.5s;

    .overlay {
      transition: inherit;
    }

    form {
      transition: all 0.25s;
      transition-delay: 0.2s;
    }
  }

  &.ng-hide-add {
    transition: all 0.3s;

    .overlay, form {
      transition: inherit;
    }

    form {
      transition: all 0.2s;
    }
  }

  &.ng-hide {
    .overlay {
      opacity: 0;
    }

    form {
      transform: translate3d(0, -100%, 0);
    }
  }

  .rsvp-thank-you {
    text-align: center;
  }
}
