* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased; }

[ng-cloak] {
  display: none; }

html, body {
  width: 100%;
  height: 100%;
  margin: 0; }

body {
  color: #111;
  -webkit-font-smoothing: antialiased;
  line-height: 1.75; }
  body:after {
    background: url("../images/background/moon.svg");
    background-repeat: no-repeat;
    background-size: 12vmin;
    content: '';
    height: 12vmin;
    width: 12vmin;
    position: fixed;
    transform: translate3d(0, 0, 0);
    top: calc(3vw + 3rem);
    left: 3vw; }

.container {
  width: 100%;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .container {
      width: 756px; } }
  @media (min-width: 1224px) {
    .container {
      width: 1010px; } }
  @media (min-width: 1440px) {
    .container {
      width: 1420px; } }
  @media (min-width: 1680px) {
    .container {
      width: 1660px; } }

.light {
  font-weight: 200; }

section {
  font-family: 'Futura W01';
  padding: 2em 0;
  overflow: hidden;
  z-index: 1;
  position: relative; }
  section h1 {
    font-size: 48px;
    text-align: center;
    padding-bottom: 0.4em;
    margin-bottom: 1em;
    position: relative;
    font-weight: 900; }
    section h1:after {
      content: '';
      display: block;
      width: 2em;
      height: 2px;
      background: rgba(255, 255, 255, 0.4);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto; }

.overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  opacity: 1; }

.site-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9998;
  border-bottom: 1px solid #51917f;
  display: block;
  width: 100%;
  transform: translate3d(0, -225px, 0);
  backface-visibility: hidden; }
  .site-nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    height: 3rem;
    padding: 0;
    align-items: stretch;
    flex-direction: row;
    position: relative;
    z-index: 1;
    background: #8ABEAF; }
  .site-nav li {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center; }
    .site-nav li.hamburger a {
      height: 50px;
      width: auto; }
      .site-nav li.hamburger a svg {
        height: 100%; }
        .site-nav li.hamburger a svg path {
          fill: #E6E5EB;
          transition: 0.3s fill; }
      .site-nav li.hamburger a:hover {
        background: none; }
        .site-nav li.hamburger a:hover svg path {
          fill: #f8d6db; }
    .site-nav li.desktop-nav {
      display: none; }
    .site-nav li.rsvp-link {
      margin-left: auto; }
      .site-nav li.rsvp-link a {
        background: #E6E5EB;
        color: #000; }
        .site-nav li.rsvp-link a:hover {
          background: #f8d6db; }
        .site-nav li.rsvp-link a:active {
          background: #F1AAB4; }
    @media (min-width: 580px) {
      .site-nav li.desktop-nav {
        display: flex; }
      .site-nav li.hamburger {
        display: none; } }
  .site-nav a {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    padding: 0.3em 0.7em 0.4em;
    color: #000;
    text-decoration: none;
    font-family: "Futura W01";
    font-size: 1.2em;
    transition: 0.3s background; }
    .site-nav a:hover {
      background: #E6E5EB; }
    .site-nav a:active {
      background: #488170; }
  .site-nav .mobile-nav {
    top: 0;
    left: 0;
    height: 225px;
    width: 100%;
    z-index: 9999;
    flex-direction: column;
    background: #abd0c5; }
    .site-nav .mobile-nav li {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0; }
  .site-nav.show-mobile-nav .hamburger a {
    transform: rotate(180deg); }
  .site-nav.show-mobile-nav .mobile-nav {
    display: flex; }
    @media (min-width: 580px) {
      .site-nav.show-mobile-nav .mobile-nav {
        display: none; } }
  .site-nav.show-mobile-nav-add {
    transition: all 0.6s;
    transform: translate3d(0, -225px, 0); }
  .site-nav.show-mobile-nav-add-active {
    transform: translate3d(0, 0, 0); }
  .site-nav.show-mobile-nav {
    transform: translate3d(0, 0, 0); }
  .site-nav.show-mobile-nav-remove {
    transition: all 0.6s;
    transform: translate3d(0, 0, 0); }
  .site-nav.show-mobile-nav-remove-active {
    transform: translate3d(0, -225px, 0); }
    .site-nav.show-mobile-nav-remove-active .mobile-nav {
      display: flex; }
  @media (min-width: 580px) {
    .site-nav.show-mobile-nav-add, .site-nav.show-mobile-nav, .site-nav.show-mobile-nav-remove, .site-nav.show-mobile-nav-remove-active {
      transition: none;
      transform: none; }
      .site-nav.show-mobile-nav-add .mobile-nav, .site-nav.show-mobile-nav .mobile-nav, .site-nav.show-mobile-nav-remove .mobile-nav, .site-nav.show-mobile-nav-remove-active .mobile-nav {
        display: none; } }

.nav-overlay {
  z-index: 9000;
  opacity: 1; }
  .nav-overlay.ng-hide-remove, .nav-overlay.ng-hide-add {
    transition: all 0.3s; }
  .nav-overlay.ng-hide {
    opacity: 0; }
  @media (min-width: 580px) {
    .nav-overlay, .nav-overlay.ng-hide-remove, .nav-overlay.ng-hide-add, .nav-overlay.ng-hide {
      transition: none;
      display: none; } }

.sky {
  background: linear-gradient(#00171A, #325A57 50%);
  background-position: center center;
  background-repeat: repeat;
  background-size: auto auto;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  width: 100vw;
  z-index: 0; }
  .sky .layer {
    position: absolute;
    top: 0;
    left: 0; }

.site-header {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-size: 500px auto;
  overflow: hidden;
  perspective: 0px;
  z-index: 1; }
  .site-header .title-container {
    display: flex;
    height: calc(100vh - 30vw);
    margin-top: 3rem; }
  .site-header h1 {
    font-family: 'Rockwell W01';
    font-weight: 800;
    text-transform: uppercase;
    align-self: center;
    text-align: right;
    width: 80vmin;
    margin: 0 auto;
    font-size: 14vmin;
    padding-bottom: 0.25em;
    color: #8ABEAF;
    line-height: 0.9;
    background: url("../images/and.svg") no-repeat 6% bottom;
    background-size: 40% auto;
    position: relative;
    text-shadow: 0 5px 30px rgba(0, 34, 38, 0.66);
    z-index: 1; }
  .site-header .background, .site-header .background > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: 100% auto;
    z-index: 2;
    transform-style: preserve-3d;
    transform: translateZ(0); }
    .site-header .background .b7, .site-header .background > div .b7 {
      z-index: 3; }
  .site-header .b7 {
    background-image: url("../images/background/7.svg");
    z-index: 7; }
  .site-header .b6 {
    background-image: url("../images/background/6.svg");
    z-index: 6; }
  .site-header .b5 {
    background-image: url("../images/background/5.svg");
    z-index: 5; }
  .site-header .b4 {
    background-image: url("../images/background/4.svg");
    z-index: 4; }
  .site-header .b3 {
    background-image: url("../images/background/3.svg");
    z-index: 3; }
  .site-header .b2 {
    background-image: url("../images/background/2.svg");
    z-index: 2; }
  .site-header .b1 {
    background-image: url("../images/background/1.svg");
    z-index: 1; }
  .site-header .b7 {
    background-image: url("../images/background/7-old.svg"); }

.introduction {
  background: #330017;
  color: #fff;
  font-size: 16px;
  margin-top: -2px; }
  @media (min-width: 1224px) {
    .introduction {
      font-size: 15px; } }
  @media (min-width: 1440px) {
    .introduction {
      font-size: 19px; } }
  @media (min-width: 1680px) {
    .introduction {
      font-size: 22px; } }
  .introduction h2 {
    text-align: left;
    font-family: 'Rockwell W01';
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center; }
    @media (min-width: 1224px) {
      .introduction h2 {
        text-align: left;
        font-size: 1.8em; } }
    @media (min-width: 1024px) {
      .introduction h2 {
        font-size: 1.6em; } }
  .introduction .grid {
    display: flex;
    flex-wrap: wrap; }
  .introduction .when, .introduction .where, .introduction .what, .introduction .attire, .introduction .map {
    overflow: hidden;
    margin: 0;
    font-size: 1em;
    text-align: center;
    padding: 1em; }
    @media (min-width: 1224px) {
      .introduction .when, .introduction .where, .introduction .what, .introduction .attire, .introduction .map {
        text-align: left;
        padding: 1.5em; } }
    @media (min-width: 1440px) {
      .introduction .when, .introduction .where, .introduction .what, .introduction .attire, .introduction .map {
        padding: 2em; } }
  .introduction .what, .introduction .attire {
    border-top: 2px solid rgba(255, 255, 255, 0.3); }
  .introduction .when, .introduction .where {
    border-bottom: 2px solid rgba(255, 255, 255, 0.3); }
    @media (min-width: 1024px) {
      .introduction .when, .introduction .where {
        border-bottom: none; } }
  @media (min-width: 1024px) {
    .introduction .attire, .introduction .where {
      border-left: 2px solid rgba(255, 255, 255, 0.3); } }
  .introduction .when {
    font-size: 1em;
    text-align: center;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    width: 100%; }
    @media (min-width: 768px) {
      .introduction .when {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        width: 50%; } }
    @media (min-width: 1224px) {
      .introduction .when {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
        width: 24%; } }
    .introduction .when .block-content {
      text-align: center;
      display: inline-block; }
      @media (min-width: 1224px) {
        .introduction .when .block-content {
          font-size: 0.9em; } }
      @media (min-width: 1440px) {
        .introduction .when .block-content {
          font-size: 1em; } }
    .introduction .when .month-day {
      font-size: 3em;
      font-weight: 200;
      letter-spacing: -0.02em;
      margin-bottom: -1em; }
    .introduction .when .year, .introduction .when .time {
      font-size: 5em;
      margin-bottom: -0.75em; }
    .introduction .when .year {
      letter-spacing: 0.06em;
      font-weight: 500; }
    .introduction .when .time {
      letter-spacing: 0.06em;
      font-weight: 800; }
  .introduction .where {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    width: 100%; }
    @media (min-width: 768px) {
      .introduction .where {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        width: 50%;
        border-left: 2px solid rgba(255, 255, 255, 0.3); } }
    @media (min-width: 1224px) {
      .introduction .where {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        width: 22%; } }
    .introduction .where .info {
      font-size: 1.3em; }
      @media (min-width: 1224px) {
        .introduction .where .info {
          font-size: 1.15em; } }
    .introduction .where h3 {
      font-weight: 200;
      font-size: 2.3em;
      margin: 0; }
      @media (min-width: 1224px) {
        .introduction .where h3 {
          font-size: 2.2em; } }
      @media (min-width: 1440px) {
        .introduction .where h3 {
          font-size: 2.3em; } }
    .introduction .where a {
      color: #fff; }
  .introduction .map {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    width: 100%; }
    @media (min-width: 1024px) {
      .introduction .map {
        border-top: 2px solid rgba(255, 255, 255, 0.3); } }
    @media (min-width: 1224px) {
      .introduction .map {
        flex-grow: 4;
        flex-shrink: 1;
        flex-basis: auto;
        width: 50%;
        border-left: 2px solid rgba(255, 255, 255, 0.3);
        border-top: none; } }
    .introduction .map iframe {
      display: block;
      width: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
      min-height: 18em; }
      @media (min-width: 1224px) {
        .introduction .map iframe {
          min-height: 10em; } }
  .introduction .what, .introduction .attire {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    width: 100%;
    border-top: 2px solid rgba(255, 255, 255, 0.3); }
    @media (min-width: 1024px) {
      .introduction .what, .introduction .attire {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        width: 50%; } }

.places-to-stay {
  background: #E6E5EB;
  background: url("../images/background/bg-hotels.png");
  text-align: center;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 5em; }
  .places-to-stay h1:after {
    background: rgba(0, 0, 0, 0.4); }
  .places-to-stay p {
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto; }
  .places-to-stay h3 {
    margin-bottom: 0.5em; }
  .places-to-stay a {
    color: #51917f; }
    .places-to-stay a:hover {
      color: #2c5045; }
    .places-to-stay a:active {
      color: #000; }
  .places-to-stay .hotels {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: 4em auto; }
    @media (min-width: 768px) {
      .places-to-stay .hotels {
        flex-direction: row; } }
    .places-to-stay .hotels a {
      background: #51917f;
      transition: 0.3s background;
      color: #fff;
      display: inline-block;
      width: 6em;
      text-decoration: none;
      font-size: 18px;
      text-transform: uppercase;
      padding: 0.3em 0; }
      .places-to-stay .hotels a:hover {
        background: #2c5045;
        color: #fff; }
      .places-to-stay .hotels a:active {
        color: #000; }
      .places-to-stay .hotels a:first-of-type {
        margin-right: 0.5em; }
    .places-to-stay .hotels li {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto; }
      @media (min-width: 768px) {
        .places-to-stay .hotels li {
          flex-grow: 1;
          flex-shrink: 1;
          flex-basis: 0;
          margin-bottom: 0; } }
      .places-to-stay .hotels li:first-child {
        margin-bottom: 2em; }
        @media (min-width: 768px) {
          .places-to-stay .hotels li:first-child {
            margin-bottom: 0; } }
    .places-to-stay .hotels img {
      width: 70%;
      border-radius: 50%;
      border: 4px solid #fff;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.6); }

.registry {
  background: #8ABEAF;
  border-top: 1px solid #51917f; }
  .registry h1:after {
    background: rgba(0, 0, 0, 0.4); }
  .registry .content {
    display: flex;
    flex-wrap: wrap; }
  .registry .registry-info, .registry .registry-links {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    width: 100%;
    padding: 0 2em; }
    @media (min-width: 1024px) {
      .registry .registry-info, .registry .registry-links {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        width: 65%; } }
    @media (min-width: 1224px) {
      .registry .registry-info, .registry .registry-links {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        width: 50%; } }
  .registry .registry-links {
    list-style: none;
    padding: 0 2em;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start; }
    @media (min-width: 1024px) {
      .registry .registry-links {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        width: 35%; } }
    @media (min-width: 1224px) {
      .registry .registry-links {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        width: 50%; } }
    .registry .registry-links li {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
      width: 50%;
      display: flex;
      padding: 0.5em; }
      .registry .registry-links li:nth-child(odd) {
        padding-left: 0; }
      .registry .registry-links li:nth-child(even) {
        padding-right: 0; }
      @media (min-width: 1024px) {
        .registry .registry-links li {
          flex-grow: 1;
          flex-shrink: 1;
          flex-basis: auto;
          width: 100%;
          padding: 1em; }
          .registry .registry-links li:nth-child(odd) {
            padding-left: 1em; }
          .registry .registry-links li:nth-child(even) {
            padding-right: 1em; } }
      @media (min-width: 1224px) {
        .registry .registry-links li {
          flex-grow: 1;
          flex-shrink: 1;
          flex-basis: auto;
          width: 50%; } }
      .registry .registry-links li a {
        align-self: center;
        background: #E6E5EB;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 3px;
        display: block;
        width: 100%;
        font-size: 0;
        height: 130px;
        transition: 0.3s border, 0.3s background;
        position: relative; }
        .registry .registry-links li a:hover {
          border: 1px solid black; }
        .registry .registry-links li a:active {
          background: #d8d7e0; }
      .registry .registry-links li .name {
        position: absolute;
        background: 50% 50% no-repeat;
        background-size: contain;
        opacity: 0.6;
        transition: 0.3s opacity;
        top: 12px;
        bottom: 12px;
        left: 24px;
        right: 24px; }
        @media (min-width: 768px) {
          .registry .registry-links li .name {
            top: 20px;
            bottom: 20px;
            left: 40px;
            right: 40px; } }
        @media (min-width: 1024px) {
          .registry .registry-links li .name {
            top: 7.5px;
            bottom: 7.5px;
            left: 15px;
            right: 15px; } }
        @media (min-width: 1224px) {
          .registry .registry-links li .name {
            top: 12px;
            bottom: 12px;
            left: 24px;
            right: 24px; } }
        @media (min-width: 1440px) {
          .registry .registry-links li .name {
            top: 17px;
            bottom: 17px;
            left: 34px;
            right: 34px; } }
      .registry .registry-links li a:hover .name {
        opacity: 1; }
      .registry .registry-links li:nth-child(1) .name {
        background-image: url("../images/leukemia_lymphoma_society.svg"); }
      .registry .registry-links li:nth-child(2) .name {
        background-image: url("../images/aspca.svg"); }
      .registry .registry-links li:nth-child(3) .name {
        background-image: url("../images/amazon.svg"); }
      .registry .registry-links li:nth-child(4) .name {
        background-image: url("../images/Bed_Bath__and__Beyond.svg"); }

.rsvp {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  padding: 0; }
  .rsvp form {
    background: #F1AAB4;
    width: calc(400px + 4em);
    max-width: 100%;
    max-height: calc(100% - 1em);
    overflow: visible;
    margin: auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    transition: 1s all;
    font-size: 20px;
    padding: 2em;
    border-bottom: 1px solid #000;
    border-radius: 0 0 10px 10px; }
    .rsvp form:after {
      content: '';
      display: table;
      clear: both; }
  .rsvp .main-info, .rsvp .additional-info {
    vertical-align: top;
    white-space: normal;
    display: block; }
    @media (min-width: 1024px) {
      .rsvp .main-info, .rsvp .additional-info {
        display: inline-block;
        width: 400px; } }
    .rsvp .main-info:after, .rsvp .additional-info:after {
      content: '';
      display: table;
      clear: both; }
  .rsvp .main-info {
    font-size: 1.3em; }
    @media (min-width: 1024px) {
      .rsvp .main-info {
        margin-right: -0.32em; } }
  .rsvp h1 {
    margin-top: 0; }
  .rsvp label {
    font-size: 1em;
    display: block;
    margin-top: 1em; }
    .rsvp label:first-child {
      margin-top: 0; }
  .rsvp input[type="text"], .rsvp textarea {
    width: 100%;
    appearance: none;
    border: none;
    font-size: 1em;
    padding: 0.3em;
    margin-top: 0.4em;
    display: block;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
    transition: 0.2s box-shadow; }
    .rsvp input[type="text"]:focus, .rsvp textarea:focus {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.4);
      outline: none; }
  .rsvp textarea {
    height: 4em; }
  .rsvp .rsvp-yes-no {
    margin: 2em 0; }
    .rsvp .rsvp-yes-no label {
      display: block;
      margin: 0.5em 0; }
      .rsvp .rsvp-yes-no label:before {
        content: '\2713';
        font-size: 2em;
        color: transparent;
        display: inline-block;
        vertical-align: middle;
        width: 0.6em;
        height: 0.6em;
        line-height: 0.5;
        background: rgba(255, 255, 255, 0.4);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
        transition: 0.2s box-shadow, 0.2s color;
        position: relative;
        top: -0.07em;
        margin-right: 0.5em; }
    .rsvp .rsvp-yes-no input[type="radio"] {
      opacity: 0;
      position: absolute; }
      .rsvp .rsvp-yes-no input[type="radio"]:checked + label:before {
        color: black; }
      .rsvp .rsvp-yes-no input[type="radio"]:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.4); }
  .rsvp button {
    appearance: none;
    border: none;
    font-size: 1em;
    background: rgba(255, 255, 255, 0.5);
    padding: 0.45em 0.75em;
    float: right;
    cursor: pointer;
    transition: 0.2s background; }
    .rsvp button.desktop-submit {
      display: none; }
    .rsvp button.mobile-submit {
      margin-top: 1em; }
    @media (min-width: 1024px) {
      .rsvp button.desktop-submit {
        display: block; }
      .rsvp button.mobile-submit {
        display: none; } }
    .rsvp button:hover {
      background: rgba(255, 255, 255, 0.8); }
  .rsvp .additional-info {
    opacity: 1;
    position: relative; }
    @media (min-width: 1024px) {
      .rsvp .additional-info {
        margin-left: 3em; } }
    .rsvp .additional-info.ng-hide-remove, .rsvp .additional-info.ng-hide-add {
      transition: opacity 0.3s; }
    .rsvp .additional-info.ng-hide {
      opacity: 0; }
    @media (min-width: 1024px) {
      .rsvp .additional-info:before {
        content: '';
        position: absolute;
        top: 1em;
        bottom: 1em;
        left: calc(-1.5em - 1px);
        width: 2px;
        background: rgba(0, 0, 0, 0.3); } }
  .rsvp label[for="name"] {
    display: block; }
  @media (min-width: 1024px) {
    .rsvp form.rsvp-yes {
      width: calc(800px + 7em); } }
  .rsvp.ng-hide-remove {
    transition: all 0.5s; }
    .rsvp.ng-hide-remove .overlay {
      transition: inherit; }
    .rsvp.ng-hide-remove form {
      transition: all 0.25s;
      transition-delay: 0.2s; }
  .rsvp.ng-hide-add {
    transition: all 0.3s; }
    .rsvp.ng-hide-add .overlay, .rsvp.ng-hide-add form {
      transition: inherit; }
    .rsvp.ng-hide-add form {
      transition: all 0.2s; }
  .rsvp.ng-hide .overlay {
    opacity: 0; }
  .rsvp.ng-hide form {
    transform: translate3d(0, -100%, 0); }
  .rsvp .rsvp-thank-you {
    text-align: center; }
