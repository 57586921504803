@mixin xl {
  @media (min-width: 1680px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 1224px) {
    @content;
  }
}

@mixin sm {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin xs {
  @media (min-width: 768px) {
    @content;
  }
}
